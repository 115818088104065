import React, { useState } from 'react';
import Button from './Button/Button';
import InputField from './InputField';
import ModalBox from './ModalBox';
import styles from './TopBarFriendsPage.module.css';

const TopBarFriendsPage = () => {
  const [inputValue, setInputValue] = useState();
  const [isShowing, setIsShowing] = useState(false);
  return (
    <div className={styles.topBar}>
      <InputField value={inputValue} name ="search" placeholder="Filter by email or name..." onChange={(e)=>setInputValue(e.target.value)}/>
      <Button primary size="large" onClick={()=>{setIsShowing(true)}}>Invite</Button>
     {isShowing && <ModalBox onClose={()=>{setIsShowing(false)}}/>}
    </div>
  );
}; 

export default TopBarFriendsPage;
