import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ButtonGroup from '../components/Button/ButtonGroup';
import Search from '../components/Search';
import Card from '../components/Card';
import HomePageCard from '../components/HomePageCard';
import styles from './HomePage.module.css';
import img from '../../src/flame.png';
import { ThemeContext } from '../context/ThemeContext';
import { countriesName, movieTypes } from '../data/home_page';

const HomePage = () => {
  const movies = useSelector((state) => state.movie.movies.slice(0, 10));

  const { theme } = useContext(ThemeContext);
  const themeClass = theme === 'light' ? styles.light : styles.dark;

  const [count, setCount] = useState(2);
  const [cardMovie, setCardMovie] = useState([]);
  useEffect(async () => {
    const res = await axios.get(
      `https://api.themoviedb.org/3/movie/now_playing?api_key=71b734c9fe036fa5b36e3d80555e9e37&language=en-US&page=1`
    );
    setCardMovie(res.data.results);
  }, []);

  const onClick = async () => {
    setCount(count + 1);
    const res = await axios.get(
      `https://api.themoviedb.org/3/movie/now_playing?api_key=71b734c9fe036fa5b36e3d80555e9e37&language=en-US&page=${count}`
    );
    const movie = res.data.results;
    const loadingMovies = cardMovie.concat(movie);
    setCardMovie(loadingMovies);
  };

  return (
    <div className={`container ${themeClass}`}>
      <Search />
      <div className={styles.popularmovies}>
        {movies.map((movie) => (
          <span key={movie.id}>
            <img src={img} />
            {movie.title}
          </span>
        ))}
      </div>
      <ButtonGroup />
      <div className={styles['movie-list']}>
        {movies
          ? movies.map((movie, index) => (
              <Card key={movie.id} title={movie.title} image={movie.poster_path} id={movie.id} order={index + 1} />
            ))
          : 'Find nothing'}
      </div>
      <div className={styles['ranking-types']}>
        <span className={styles['ranking-movietypes']}>
          <span className={styles.active}>All movies and dramas</span>
          <span>Movie</span>
          <span>Drama</span>
        </span>
        <div className={styles['type-area']}>
          <div className={styles.area}>Area</div>
          <div className={styles['all-areas']}>
            <span className={styles.active}>All areas</span>
            {countriesName.map((country) => (
              <span key={country}>{country}</span>
            ))}
          </div>
        </div>
        <div className={styles.types}>
          <div className={styles.type}>Category</div>
          <div className={styles['all-types']}>
            <span className={styles.active}>All types</span>
            {movieTypes.map((type) => (
              <span key={type}>{type}</span>
            ))}
          </div>
        </div>
        <div className={styles['type-order']}>
          <div className={styles.order}>Sort</div>
          <div className={styles['all-orders']}>
            <span className={styles.active}>Recently released</span>
            <span>Rating</span>
            <span>New</span>
            <span>Old</span>
          </div>
        </div>
        <div className={styles['type-subtitle']}>
          <div className={styles.subtitle}>Subtitile</div>
          <div className={styles['all-subtitiles']}>
            <span className={styles.active}>All languages</span>
            <span>Chinese</span>
            <span>English</span>
          </div>
        </div>
      </div>
      <div className={styles['movie-card']}>
        {cardMovie
          ? cardMovie.map((movie) => (
              <HomePageCard
                key={movie.id}
                image={movie.poster_path}
                id={movie.id}
                date={movie.release_date}
                rating={movie.vote_average}
              />
            ))
          : 'Find nothing'}
      </div>

      <button className={styles.loading} onClick={onClick}>
        Loading more
      </button>
    </div>
  );
};
HomePage.contextType = ThemeContext;
export default HomePage;
