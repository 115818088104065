import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Card.module.css';

const Card = (props) => {
  const imageBaseUrl = 'https://image.tmdb.org/t/p/w500/';
  const { title, image, id, order } = props;
  return (
    <Link to={`/movie-detail?id=${id}`}>
      <div className={styles.card}>
        <img alt="" src={`${imageBaseUrl}${image}`} />
        <div>{title}</div>
        <span>{order}</span>
      </div>
    </Link>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  id: PropTypes.string,
  order: PropTypes.string
};

export default Card;
