import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Rating } from 'react-simple-star-rating';
import styles from './RateMovie.module.css';
import MyReviewCard from './MyReviewCard';
import { rateMovie } from '../utils/request';

const RateMovie = (props) => {
  const { id, onClick } = props;
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [ratingErrorToggle, setRatingErrorToggle] = useState(false);
  const [submitSucceed, setSubmitSucceed] = useState(false);
  const [date, setDate] = useState('');
  const commentRef = useRef();

  const ratingErrorMessage = 'Rating can not be empty';

  const handleRating = (rate) => {
    setRating(rate);
  };

  const validateRating = (rating) => {
    return rating > 0 && rating <= 100;
  };

  const handleSubmit = async () => {
    let isRatingVaild = validateRating(rating);
    setRatingErrorToggle(!isRatingVaild);
    let today = new Date();
    setDate(
      `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}  ${today.getHours()}:${today.getMinutes()}`
    );

    if (isRatingVaild) {
      try {
        let movieId = parseInt(id);
        let score = rating / 10;
        await rateMovie(movieId, score, comment);
        setSubmitSucceed(true);
      } catch (e) {
        setErrorMessage(e.response.data.message);
      }
    }
  };

  return (
    <div>
      {!submitSucceed && (
        <div className={styles.container}>
          <div className={styles.rating}>
            <Rating
              onClick={handleRating}
              ratingValue={rating}
              allowHalfIcon
              showTooltip
              tooltipArray={[
                'Terrible',
                'Terrible+',
                'Bad',
                'Bad+',
                'Average',
                'Average+',
                'Great',
                'Great+',
                'Awesome',
                'Awesome+'
              ]}
            />
            <div className={styles.comments}>
              <label>Comment</label>
              <textarea
                ref={commentRef}
                name="comment"
                rows="3"
                cols="34"
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>

            <div>
              <button className={styles.button} onClick={handleSubmit}>
                Submit
              </button>
              <button className={styles.button} onClick={onClick}>
                Cancel
              </button>
            </div>

            {ratingErrorToggle && <div className={styles.error}>{ratingErrorMessage}</div>}
            <div className={styles.error}>{errorMessage}</div>
          </div>
        </div>
      )}

      <div className={styles.succeed}>
        {submitSucceed && <MyReviewCard date={date} score={rating / 10} comment={comment} />}
      </div>
    </div>
  );
};
RateMovie.propTypes = {
  id: PropTypes.number,
  onClick: PropTypes.func
};
export default RateMovie;
