import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Rating } from 'react-simple-star-rating';
import styles from './MyReviewCard.module.css';

const MyReviewCard = (props) => {
  const { date, score, comment } = props;

  const [readMore, setReadMore] = useState(false);
  const [needHidden, setNeedHidden] = useState(false);

  useEffect(() => {
    if (comment.length > 100) {
      return setNeedHidden(true);
    }
  }, [comment.length]);

  return (
    <div className={styles.card}>
      <span className={styles.name}>I have seen this before</span>
      <span className={styles.date}>{dayjs(date).format('YYYY-MM-DD HH:mm:ss')}</span>
      <span className={styles.star}>
        My rating:
        <Rating
          ratingValue={score * 10}
          allowHalfIcon
          size={20}
          allowHover={false}
          readonly
          showTooltip
          tooltipArray={[
            'Terrible',
            'Terrible+',
            'Bad',
            'Bad+',
            'Average',
            'Average+',
            'Great',
            'Great+',
            'Awesome',
            'Awesome+'
          ]}
        />
      </span>
      <div>
        {needHidden && !readMore ? `${comment.substring(0, 90)}...` : comment}
        {needHidden && (
          <button className={styles.show} onClick={() => setReadMore(!readMore)}>
            {readMore ? 'Stow' : 'Expand'}
          </button>
        )}
      </div>
    </div>
  );
};

MyReviewCard.propTypes = {
  comment: PropTypes.string,
  date: PropTypes.string,
  score: PropTypes.number
};

export default MyReviewCard;
