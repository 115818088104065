import React, { useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './ResetPasswordPage.module.css';
import { changePwd } from '../utils/request';
import LabelInput from '../components/LabelInput';

const ChangePasswordPage = () => {
  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [oldPasswordErrorToggle, setOldPasswordErrorToggle] = useState(false);
  const [newPasswordErrorToggle, setNewPasswordErrorToggle] = useState(false);
  const [changeSucceed, setChangeSucceed] = useState(false);
  const [isShowingLoading, setIsShowingLoading] = useState(false);

  const inputErrorMessage = {
    oldPassword: 'The password can not be empty',
    newPassword:
      'Password should contains at least one upper case letter, one lower case letter one number and one special character and within 8 to 16 characters'
  };

  const validateNewPassword = (newPassword) => {
    return newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~!@#$%^&*]{8,16}$/);
  };
  const validateOldPassword = (oldPassword) => {
    return oldPassword !== '';
  };

  const handleLogin = async () => {
    const oldPassword = oldPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;

    let isOldPasswordVaild = validateOldPassword(oldPassword);
    setOldPasswordErrorToggle(!isOldPasswordVaild);

    let isNewPasswordVaild = validateNewPassword(newPassword);
    setNewPasswordErrorToggle(!isNewPasswordVaild);

    if (isOldPasswordVaild && isNewPasswordVaild) {
      try {
        setIsShowingLoading(true);
        await changePwd(oldPassword, newPassword);
        setIsShowingLoading(false);
        setChangeSucceed(true);
        toast.success('Yeah! Your password has been changed.');
      } catch (e) {
        setIsShowingLoading(false);
        setErrorMessage(e.response.data.message);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  useEffect(() => {
    oldPasswordRef.current.focus();
  }, []);

  return (
    <div className={styles.container}>
      <h2>Change Password</h2>
      <div className={styles.error}>{errorMessage}</div>
      <form onSubmit={handleSubmit}>
        <LabelInput type={'password'} labelName={'Current password'} inputRef={oldPasswordRef} name={'oldPassword'} />

        {/* <label>Current password</label>
        <input ref={oldPasswordRef} name="oldPassword" type="password"></input> */}
        <div className={styles.error}>{oldPasswordErrorToggle && inputErrorMessage.oldPassword}</div>
        <LabelInput type={'password'} labelName={'New password'} inputRef={newPasswordRef} name={'newPassword'} />

        {/* <label>New password</label>
        <input ref={newPasswordRef} name="newPassword" type="password"></input> */}
        <div className={styles.error}>{newPasswordErrorToggle && inputErrorMessage.newPassword}</div>
        <button className={styles.button}>{isShowingLoading && <ClipLoader color="#fff" size={20} />} Submit</button>
      </form>
      <div className={styles.succeed}>
        {changeSucceed && (
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
          />
        )}
      </div>
    </div>
  );
};
export default ChangePasswordPage;
