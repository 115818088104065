import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReviewCard from './ReviewCard';
import styles from './CommentsList.module.css';
import { reviewList } from '../utils/request';

const CommentsList = ({ movieId, checkBtn }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [reviews, setReviews] = useState([]);
  const [isOnShowing, setIsOnShowing] = useState(false);
  const userId = localStorage.getItem('maimiao-user-id');

  useEffect(async () => {
    try {
      const profile = await reviewList(movieId);
      setReviews(profile.result);
      checkBtn(profile.result.length > 1);
    } catch (e) {
      setErrorMessage(e.response.data.message);
    }
  }, []);

  let comments = reviews.filter((review) => review.user.id !== userId);

  return (
    <div className={styles.container}>
      <button onClick={() => setIsOnShowing(!isOnShowing)}>Read All Reviews</button>
      <div className={styles.review}>
        {isOnShowing &&
          comments.map((comment) => (
            <ReviewCard
              key={comment.id}
              date={comment.createdAt}
              person={comment.user}
              score={comment.score}
              comment={comment.comment}
            />
          ))}
      </div>
      <div className={styles.error}>{errorMessage}</div>
    </div>
  );
};
CommentsList.propTypes = {
  movieId: PropTypes.number,
  checkBtn: PropTypes.func
};
export default CommentsList;
