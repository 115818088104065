import React from 'react';
import PropTypes from 'prop-types';
import styles from './Avatar.module.css';

const Avatar = (props) => {
  return <div className={styles.icon}>{props.children}</div>;
};

Avatar.propTypes = {
  children: PropTypes.string
};

export default Avatar;
