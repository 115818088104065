import React from 'react';
import styles from './AboutPage.module.css';

const AboutPage = () => {
  return (
    <div className={styles.about}>
      <div className={styles.feature}>
        <h1>Tastesay</h1>
        <p>Loving movies, love sharing</p>
      </div>

      <div className={styles.contents}>
        <div className={styles.content}>
          <div className={styles.img}>
            <img src={process.env.PUBLIC_URL + '/img/lake.jpg'} />
          </div>
          <div className={styles.intro}>
            <h1>We are Movie teller</h1>
            <p>
              We love movies. We love every spark that could make the world to be a better place. And this motivates us
              to found this company, sharing the movies and trying our best to use our skills to make the movies match
              your taste.
            </p>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.intro}>
            <h1>Our Team</h1>
            <p>
              We are a team of talented developers extremely passionate about software development. To get it right for
              you today, tomorrow and in the future, we continuously study the latest trends, adapt to new solutions and
              use the most up to date technology available.
            </p>
          </div>
          <div className={styles.img}>
            <img src={process.env.PUBLIC_URL + '/img/morning.jpg'} />
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.img}>
            <img src={process.env.PUBLIC_URL + '/img/city.jpg'} />
          </div>
          <div className={styles.intro}>
            <h1>How we work</h1>
            <p>
              Our expertise is based on the way we develop software to bring ideas to market. We focus on practices that
              unlock a pipeline of ideas, in a way that allows those ideas to be delivered incrementally and to be
              validated by real users. We couple rapid development practises with a mindset of doing just enough to
              bring an idea to market. No more no less.
            </p>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.intro}>
            <h1>What we believe</h1>
            <p>
              We believe developers can be far more productive when given the freedom to lead in the process of crafting
              solutions. It is less about what they do and more about what they choose not to do when creating a great
              software solution. We believe attention should be given to “building the right thing“. Building the wrong
              thing – not getting user feedback early and often – is the true failure.
            </p>
          </div>
          <div className={styles.img}>
            <img src={process.env.PUBLIC_URL + '/img/sky.jpg'} />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <h1>OUR MISSION</h1>
        <p>
          We are dedicated to the wenderful movies that make the world a better place for our clients, their users, and
          our community.
        </p>
      </div>
    </div>
  );
};

export default AboutPage;
