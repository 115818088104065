import React, { useEffect, useState } from 'react';
import { getReceivedInvitations } from '../utils/request';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import FriendCard from './FriendCard';
dayjs.extend(relativeTime);

const ReceivedInvitations = () => {
  const [invitationsList, setInvitaionsList] = useState([]);

  useEffect(async () => {
    const result = await getReceivedInvitations();
    setInvitaionsList(result);
  }, []);

  return (
    <div>
      {invitationsList &&
        invitationsList.map((invitation) => (
          <FriendCard
            key={invitation.id}
            status={`\u00A0 Received at \u00A0
              ${dayjs(invitation.createdAt).fromNow()}`}
            title={`${invitation.from.firstName} ${invitation.from.lastName}`}
            subTitle={invitation.from.email}
            actions={['Accept', 'Reject']}
          />
        ))}
    </div>
  );
};
export default ReceivedInvitations;
