import React from 'react';
import PropTypes from 'prop-types';
import styles from './ModalBox.module.css';
import AutoComplete from './AutoComplete';

const ModalBox = ({ onClose }) => {

  return (
    <div className={styles.modal} >
      <div className={styles.modalContent}>
        <AutoComplete/>
        <span className={styles.close} onClick={onClose}>&times;</span>
      </div>
    </div>
  );
};

ModalBox.propTypes = {
  onClose: PropTypes.func
};
export default ModalBox;
