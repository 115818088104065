import { useSearchParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './MovieDetailPage.module.css';
import { ThemeContext } from '../context/ThemeContext';
import RateMovie from '../components/RateMovie';
import MyReviewCard from '../components/MyReviewCard';
import CommentsList from '../components/CommentsList';
import { myReview } from '../utils/request';

const MovieDetailPage = () => {
  const [searchParams] = useSearchParams();
  const movieId = searchParams.get('id');
  const [movie, setMovie] = useState({});
  const [reviews, setReviews] = useState([]);
  const [hasComment, setHasComment] = useState(true);
  const { theme } = useContext(ThemeContext);
  const [isAddingRating, setIsAddingRating] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const navigate = useNavigate();
  const themeClass = theme === 'light' ? styles.light : styles.dark;

  useEffect(async () => {
    const res = await axios.get(
      `https://api.themoviedb.org/3/movie/${movieId}?api_key=71b734c9fe036fa5b36e3d80555e9e37&language=en-US&page=1`
    );
    setMovie(res.data);
  }, [movieId]);

  useEffect(async () => {
    const userId = localStorage.getItem('maimiao-user-id');
    const profile = await myReview(movieId, userId);
    setReviews(profile.result);
  }, [movieId]);

  const updateReviewBtn = (status) => {
    console.log(`btn status`, status);
    setHasComment(status);
  };

  const { title, overview, popularity, poster_path, release_date, revenue, budget, vote_average } = movie;
  const imageBaseUrl = 'https://image.tmdb.org/t/p/w500/';

  return (
    <div className={`container ${themeClass}`}>
      <div className={styles.back} onClick={() => navigate(-1)}>
        &lt; Back
      </div>
      <div className={styles.content}>
        <div className={styles.imgColumn}>
          <div className={styles.picture}>
            <img alt=" " src={`${imageBaseUrl}${poster_path}`} />
          </div>
          {reviews.length === 0 && (
            <button className={styles.button} onClick={() => (setIsAddingRating(true), setIsCancel(false))}>
              Rate it
            </button>
          )}
        </div>

        <div className={styles.details}>
          <div className={styles.header}>
            <span className={styles.name}>{title}</span>
            <span className={styles.rating}>{vote_average}/10</span>
          </div>
          <h2>Film Intro</h2>
          <h3>{overview}</h3>
          <hr />
          <div className={styles.statistic}>
            <div className={styles.item}>
              <span>Revenue</span>
              <span>${revenue}</span>
            </div>
            <div className={styles.item}>
              <span>Cost</span>
              <span>${budget}</span>
            </div>
            <div className={styles.item}>
              <span>Release Date</span>
              <span>{release_date}</span>
            </div>
            <div className={styles.item}>
              <span>Popularity</span>
              <span>{popularity}</span>
            </div>
          </div>
        </div>
      </div>{' '}
      <div className={styles.rate}>
        {reviews.length !== 0 &&
          reviews.map((review) => (
            <MyReviewCard key={review.id} date={review.createdAt} score={review.score} comment={review.comment} />
          ))}
        {isAddingRating && !isCancel && <RateMovie id={movieId} onClick={() => setIsCancel(true)} />}
        {hasComment && <CommentsList movieId={movieId} checkBtn={updateReviewBtn} />}
      </div>
    </div>
  );
};

export default MovieDetailPage;
