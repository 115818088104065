import React from 'react';
import styles from './SignupCompletePage.module.css';
const SignupCompletePage = () => {
  return (
    <>
      {/* eslint-disable-next-line */}
      <img src={process.env.PUBLIC_URL + '/img/cherrs.jpg'} className={styles.img} />
      <div className={styles.div}>We have sent you an email. Please follow the steps from the email to activate your account. </div>
      <div className={styles.div}>We&apos;re excited to show you around. </div>
    </>
  );
};
export default SignupCompletePage;
