import React from 'react';
import TopBarFriendsPage from '../components/TopBarFriendsPage';
import FriendCard from '../components/FriendCard';
import ReceivedInvitations from '../components/ReceivedInvitations';
import SentInvitations from '../components/SentInvitations';

const FriendsPage = () => {
  const actions = ['Delete', 'Comment'];
  return (
    <div>
      <TopBarFriendsPage />
      <FriendCard title="Ling" subTitle="1012574152@qq.com" actions={actions} />
      <SentInvitations />
      <ReceivedInvitations />
    </div>
  );
};
export default FriendsPage;
