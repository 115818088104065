import React, { useEffect, useState, useRef } from 'react';
import { getFriendsUsername, inviteFriend } from '../utils/request';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './AutoComplete.module.css';

const AutoComplete = () => {
  const containerRef = useRef();
  const [friendId, setFriendId] = useState('');
  const [comment, setComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
  // const [selectedValue, setSelectedValue] = useState('');
  const [friendsList, setFriendsList] = useState([]);
  const [isCancelShowing, setIsCancelShowing] = useState(false);
  const [isArrowUp, setIsArrowUp] = useState(false);
  const [listVisible, setListVisible] = useState(false);
  const [placeholderUp, setPlaceholderUp] = useState(false);
  const [placeholder, setPlaceholder] = useState('Filter by email');
  const [submitSucceed, setSubmitSucceed] = useState(false);

  const directionClass = isArrowUp ? styles.up : styles.down;
  const cancelClass = isCancelShowing ? styles.display : styles.cancel;
  const phClass = placeholderUp ? styles.placeholder : styles.placeholderHidden;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setInputValue('');
        setListVisible(false);
        setPlaceholderUp(false);
        setPlaceholder('Filter by email');
        setIsArrowUp(false);
        setIsCancelShowing(false);
      }
    };
    // const handleClickOutside = (event) => {
    //   if (containerRef.current && !containerRef.current.contains(event.target) && selectedValue ==='') {
    //     setInputValue('');
    //     setListVisible(false);
    //     setPlaceholderUp(false);
    //     setPlaceholder('Filter by email');
    //     setIsArrowUp(false);
    //     setIsCancelShowing(false);
    //   } else if (containerRef.current && !containerRef.current.contains(event.target) && selectedValue !=="") {
    //     setInputValue(selectedValue);
    //     setListVisible(false);
    //     setPlaceholderUp(true);
    //     setPlaceholder('');
    //     setIsArrowUp(false);
    //     setIsCancelShowing(false);
    //   }
    // };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(async () => {
    if (inputValue !== '') {
      const data = await getFriendsUsername(inputValue);
      setFriendsList(data.result);
    }
  }, [inputValue]);

  const handleSubmit = async () => {
    try {
      await inviteFriend(friendId, comment);
      setSubmitSucceed(true);
      toast.success('Yeah! Your invation has been sent.');
    } catch (e) {
      setErrorMessage(e.response.data.message);
    }
  };
  const handleCancel = () => {
    setComment('');
    setInputValue('');
    setListVisible(false);
    setPlaceholderUp(false);
    setPlaceholder('Filter by email');
    setIsArrowUp(false);
    setIsCancelShowing(false);
    setErrorMessage('');
  };

  return (
    <div ref={containerRef} className={styles.modal}>
      <div className={styles.search}>
        <input
          type="text"
          placeholder={placeholder}
          name="search"
          value={inputValue}
          autoComplete="off"
          onChange={(e) => setInputValue(e.target.value)}
          onClick={() => {
            setIsArrowUp(true);
            setListVisible(true);
            setPlaceholderUp(true);
            setPlaceholder();
            setErrorMessage('');
          }}
        />
        <div className={phClass}> Email </div>
        <div className={styles.buttons}>
          <span
            className={cancelClass}
            onClick={() => {
              setInputValue('');
              setIsCancelShowing(false);
              setListVisible(false);
              setErrorMessage('');
            }}
          >
            &times;
          </span>

          <span
            className={directionClass}
            onClick={() => {
              setIsArrowUp(!isArrowUp);
              setListVisible(!listVisible);
            }}
          >
            &#9652;
          </span>
        </div>
      </div>

      {listVisible && (
        <div className={styles.items}>
          {friendsList.map((friend) => (
            <div
              key={friend.id}
              className={styles.item}
              onClick={() => {
                setInputValue(friend.email);
                // setSelectedValue(friend.email);
                setFriendId(friend.id);
                setIsCancelShowing(true);
                setIsArrowUp(false);
                setListVisible(false);
              }}
            >
              {friend.email}
            </div>
          ))}
        </div>
      )}

      <div className={styles.submit}>
        <div>
          <label>Message</label>
          <textarea
            name="comment"
            rows="2"
            cols="33"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>
        <div>
          <button onClick={handleSubmit} className={styles.button}>Submit</button>
          <button onClick={handleCancel} className={styles.button}>Cancel</button>
        </div>
      </div>

      <div className={styles.error}>{errorMessage}</div>

      <div className={styles.succeed}>
        {submitSucceed && (
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
          />
        )}
      </div>
    </div>
  );
};
export default AutoComplete;
