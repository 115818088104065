import React, { useEffect, useState } from 'react';
// import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isLoggedIn } from './utils/anthentication';
import { updateLoginStatus } from './slices/accountSlice';
// import HomePage from './pages/HomePage';
// import AboutPage from './pages/AboutPage';
// import LoginPage from './pages/LoginPage';
// import LogoutPage from './pages/LogoutPage';`
// import SignupPage from './pages/SignupPage';
// import SignupCompletePage from './pages/SignupCompletePage';
// import ProfilePage from './pages/ProfilePage';
// import ResetPasswordPage from './pages/ResetPasswordPage';
// import MovieDetailPage from './pages/MovieDetailPage';
// import AccountActivationPage from './pages/AccountActivationPage';
// import SearchDetailPage from './pages/SearchDetailPage';
// import AccountActivationSucceedPage from './pages/AccountActivationSucceedPage';
// import FindYourAccountPage from './pages/FindYourAccountPage';
// import TermsConditionsPage from './pages/TermsConditionsPage';
// import ChangePasswordPage from './pages/ChangePasswordPage';
// import Header from './components/Header';
import Footer from './components/Footer';
import { ThemeContextProvider } from './context/ThemeContext';
import styles from './App.module.css';
// import FriendsPage from './pages/FriendsPage';
// import { ThemeContext } from './context/ThemeContext';
import Routers from './Routers';

function App() {
  const [theme, setTheme] = useState('light');
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(updateLoginStatus(true));
    }
  }, []);
  return (
    <div className={styles.app}>
      <ThemeContextProvider value={{ theme: theme, updateTheme: setTheme }}>
        {/* <Header /> */}
        {/* <div className={`${styles.content} ${themeClass}`}>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/account-activation" element={<AccountActivationPage />} />
            <Route path="/activation-succeed" element={<AccountActivationSucceedPage />} />
            <Route path="/movie-detail" element={<MovieDetailPage />} />
            <Route path="/search-detail" element={<SearchDetailPage />} />
            <Route path="/signup-complete" element={<SignupCompletePage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/find-account" element={<FindYourAccountPage />} />
            <Route path="/change-password" element={<ChangePasswordPage />} />
            <Route path="/terms-conditions" element={<TermsConditionsPage />} />
            <Route path="/friends" element={<FriendsPage />} />
          </Routes>
        </div> */}
        <Routers />
      </ThemeContextProvider>
      <Footer />
    </div>
  );
}

export default App;
