import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFriends, getProfile } from '../utils/request';

export const queryProfile = createAsyncThunk('profile/queryProfile', getProfile);
export const queryFriends = createAsyncThunk('allFriends/queryFriends', getFriends);

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    profile: {},
    allFriends: {},
    isLoggedIn: false,
    loading: false,
    error: ''
  },
  reducers: {
    updateLoginStatus: (state, { payload }) => {
      state.isLoggedIn = payload;
    }
  },
  extraReducers: {
    [queryProfile.pending]: (state) => {
      state.loadig = true;
    },
    [queryProfile.fulfilled]: (state, { payload }) => {
      state.loadig = false;
      state.profile = payload;
    },
    [queryProfile.rejected]: (state) => {
      state.loadig = false;
      state.error = 'Server error, please make sure you are logged in, and try again.';
    },

    [queryFriends.pending]: (state) => {
      state.loadig = true;
    },
    [queryFriends.fulfilled]: (state, { payload }) => {
      state.loadig = false;
      state.profile = payload;
    },
    [queryFriends.rejected]: (state) => {
      state.loadig = false;
      state.error = 'Server error, please make sure you are logged in, and try again.';
    }
  }
});
export const { updateLoginStatus } = accountSlice.actions;

export default accountSlice.reducer;
