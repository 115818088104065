import React from 'react';
import PropTypes from 'prop-types';
import styles from './LabelInput.module.css';

const LabelInput = ({ labelName, type = 'text', inputRef, name }) => {
  return (
    <div className={styles.container}>
      <label>{labelName}</label>
      <input type={type} ref={inputRef} name={name}></input>
    </div>
  );
};

LabelInput.propTypes = {
  labelName: PropTypes.string,
  inputRef: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string
};

export default LabelInput;
