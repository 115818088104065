import React, { useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Link, useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../utils/anthentication';
import styles from './LoginPage.module.css';
import { useDispatch } from 'react-redux';
import { updateLoginStatus } from '../slices/accountSlice';
import { signin } from '../utils/request';
import LabelInput from '../components/LabelInput';

const LoginPage = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [usernameErrorToggle, setUsernameErrorToggle] = useState(false);
  const [passwordErrorToggle, setPasswordErrorToggle] = useState(false);
  const [isShowingLoading, setIsShowingLoading] = useState(false);
  const userNameRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  const inputErrorMessage = {
    username: 'Should be a valid email',
    password: 'Password is required'
  };

  const validateUsername = (username) => {
    return username.match(/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/);
  };
  const validatePassword = (password) => {
    return password !== '' && password !== undefined;
  };

  const handleLogin = async () => {
    const username = userNameRef.current.value;
    const password = passwordRef.current.value;

    let isUsernameVaild = validateUsername(username);
    setUsernameErrorToggle(!isUsernameVaild);

    let isPasswordVaild = validatePassword(password);
    setPasswordErrorToggle(!isPasswordVaild);

    if (isUsernameVaild && isPasswordVaild) {
      try {
        setIsShowingLoading(true);
        const { token, userId } = await signin(username, password);
        setIsShowingLoading(false);
        localStorage.setItem('maimiao-token', token);
        localStorage.setItem('maimiao-user-id', userId);
        dispatch(updateLoginStatus(true));
        navigate('/');
      } catch (e) {
        setIsShowingLoading(false);
        setErrorMessage(e.response.data.message);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleLogin();
  };

  useEffect(() => {
    userNameRef.current.focus();
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/');
    }
  }, []);

  return (
    <div className={styles.container}>
      <h1>Sign in</h1>
      <div>{errorMessage}</div>
      <form onSubmit={handleSubmit}>
        <LabelInput labelName={'Email'} inputRef={userNameRef} name={'usename'} />
        <div>{usernameErrorToggle && inputErrorMessage.username}</div>
        <LabelInput type={'password'} labelName={'Password'} inputRef={passwordRef} name={'password'} />
        <div>{passwordErrorToggle && inputErrorMessage.password}</div>
        <Link to="/find-account">Forgot password?</Link>
        <button>{isShowingLoading && <ClipLoader color="#fff" size={20} />} Sign in</button>
      </form>
      <span>
        Don&apos;t have an account yet? <Link to="/signup">Register now</Link>
      </span>
    </div>
  );
};

export default LoginPage;
