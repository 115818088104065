// import { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './MenuIcon.module.css';

const MenuIcon = (props) => {
  const { isToggled, onToggled } = props;

  return (
    <div
      className={isToggled ? `${styles.container} ${styles.toggled}` : styles.container}
      onClick={() => onToggled(!isToggled)}
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};


MenuIcon.propTypes = {
  isToggled: PropTypes.bool,
  onToggled: PropTypes.func
};

export default MenuIcon;
