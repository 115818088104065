import React from 'react';
import FriendCard from '../components/FriendCard';
import ReceivedInvitations from '../components/ReceivedInvitations';
import SentInvitations from '../components/SentInvitations';

const TermsConditionsPage = () => {
  const actions = ['Delete', 'Comment'];

  return (
    <div>
      Terms&Conditions Page
      <FriendCard title="Ling" subTitle="1012574152@qq.com" actions={actions} />
      <SentInvitations />
      <ReceivedInvitations />
    </div>
  );
};

export default TermsConditionsPage;
