import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Button.module.css';

const Button = ({ primary = false, size = 'default', fontWeight = 'default', onClick, children }) => {
  const btnClass = classNames(
    `${styles.button}`,
    { [`${styles.large}`]: size === 'large' },
    { [`${styles.small}`]: size === 'small' },
    { [`${styles.bold}`]: fontWeight === 'bold' },
    { [`${styles.primary}`]: primary }
  );
  return (
    <span className={btnClass} onClick={onClick}>
      {children}
    </span>
  );
};

Button.propTypes = {
  primary: PropTypes.bool,
  fontWeight: PropTypes.string,
  onClick: PropTypes.func,
  bigger: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.string
};

export default Button;
