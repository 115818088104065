import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { queryProfile } from '../slices/accountSlice';
import styles from './ProfilePage.module.css';
import { updateProfile } from '../utils/request';

const ProfilePage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [firstNameErrorToggle, setFirstNameErrorToggle] = useState(false);
  const [lastNameErrorToggle, setLastNameErrorToggle] = useState(false);
  const [genderErrorToggle, setGenderErrorToggle] = useState(false);
  const [ageErrorToggle, setAgeErrorToggle] = useState(false);
  const [updateSucceed, setUpdateSucceed] = useState(false);
  const [isShowingLoading, setIsShowingLoading] = useState(false);

  const [profile, setProfile] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(queryProfile());
  }, []);

  const data = useSelector((state) => state.account.profile);

  useEffect(() => {
    setProfile(data);
  }, [data]);

  const handleChange = (field, value) => {
    setProfile({ ...profile, ...{ [field]: value } });
  };

  const inputErrorMessage = {
    firstName: 'First name should contains 1-25 characters',
    lastName: 'Last name should contains 1-25 characters',
    gender: 'Gender must be a valid enum value',
    age: 'Age must be a positive number'
  };

  const validateFirstName = (firstName) => {
    return firstName.length >= 1 && firstName.length <= 25;
  };
  const validateLastName = (lastName) => {
    return lastName.length >= 1 && lastName.length <= 25;
  };
  const validateGender = (gender) => {
    return gender === 'MALE' || gender === 'FEMALE' || gender === 'OTHER';
  };
  const validateAge = (age) => {
    return age >= 1;
  };

  const handleSignup = async () => {
    let isFirstNameValid = validateFirstName(profile.firstName);
    setFirstNameErrorToggle(!isFirstNameValid);

    let isLastNameValid = validateLastName(profile.lastName);
    setLastNameErrorToggle(!isLastNameValid);

    let isGenderValid = validateGender(profile.gender);
    setGenderErrorToggle(!isGenderValid);

    let isAgeValid = validateAge(parseInt(profile.age));
    setAgeErrorToggle(!isAgeValid);

    if (isFirstNameValid && isLastNameValid && isGenderValid && isAgeValid) {
      try {
        setIsShowingLoading(true);
        await updateProfile(profile);
        setIsShowingLoading(false);
        setUpdateSucceed(true);
        toast.success('Your profile has been updated.');
      } catch (e) {
        setIsShowingLoading(false);
        setErrorMessage(e.response.data.fields);
      }
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleSignup();
  }

  return (
    <div className={styles.container}>
      <h1>Profile</h1>
      <form onSubmit={handleSubmit}>
        <label className={styles.label}>First name</label>
        <input
          name="firstname"
          value={profile.firstName}
          onChange={(e) => {
            handleChange('firstName', e.target.value);
          }}
        />
        <div className={styles.error}>{firstNameErrorToggle && inputErrorMessage.firstName}</div>

        <label className={styles.label}>Last name</label>
        <input
          name="lastname"
          value={profile.lastName}
          onChange={(e) => {
            handleChange('lastName', e.target.value);
          }}
        />
        <div className={styles.error}>{lastNameErrorToggle && inputErrorMessage.lastName}</div>

        <label className={styles.label}>Gender</label>
        <div className={styles.radio}>
          <input
            id="male"
            value="MALE"
            name="gender"
            type="radio"
            onChange={(e) => {
              handleChange('gender', e.target.value);
            }}
            checked={profile.gender === 'MALE'}
          />
          Male
          <input
            id="FEMALE"
            value="FEMALE"
            name="gender"
            type="radio"
            onChange={(e) => {
              handleChange('gender', e.target.value);
            }}
            checked={profile.gender === 'FEMALE'}
          />
          Female
          <input
            id="other"
            value="OTHER"
            name="gender"
            type="radio"
            onChange={(e) => {
              handleChange('gender', e.target.value);
            }}
            checked={profile.gender === 'OTHER'}
          />
          Other
        </div>
        <div className={styles.error}>{genderErrorToggle && inputErrorMessage.gender}</div>

        <label className={styles.label}>Age</label>
        <input
          name="age"
          value={profile.age}
          onChange={(e) => {
            handleChange('age', parseInt(e.target.value));
          }}
        />
        <div className={styles.error}>{ageErrorToggle && inputErrorMessage.age}</div>
        <button className={styles.button}>
          {' '}
          {isShowingLoading && <ClipLoader color="#fff" size={20} />} Update profile
        </button>
      </form>

      <div className={styles.error}>{errorMessage}</div>

      <div className={styles.succeed}>
        {updateSucceed && (
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
          />
        )}
      </div>
    </div>
  );
};
export default ProfilePage;
