const buttonGroup = [
  {
    id: 'onshowing',
    label: 'OnShowing'
  },
  {
    id: 'popular',
    label: 'Popular'
  },
  {
    id: 'toprate',
    label: 'TopRate'
  }
  //   {
  //     id: 'latest',
  //     label: 'Latest'
  //   }
];

const countriesName = [
  'Singapore',
  'Thailand',
  'Hongkong',
  'Malaysia',
  'India',
  'Philippines',
  'Czech Republic',
  'Hungary',
  'South Africa',
  'Australia',
  'UK',
  'Israel',
  'Slovakia',
  'Russia',
  'Iceland',
  'Lithuania',
  'Rumania',
  'Mexico',
  'Greece',
  'Argentina',
  'Canada',
  'Germany',
  'Japan',
  'Switzerland',
  'Brazil',
  'Turdey',
  'Belgium',
  'Italy',
  'Korea',
  'Spain',
  'Netherland',
  'Columbia',
  'Ukraine',
  'America',
  'Sweden',
  'France',
  'Portugal',
  'Poland'
];
const movieTypes = [
  'Comedy',
  'Action',
  'Romantic',
  'Adventure',
  'Horror',
  'Science Fiction',
  'Western',
  'Family',
  'Biography',
  'Documentary',
  'Thriller',
  'Cartoon',
  'Music',
  'History',
  'Sports',
  'War',
  'Dance',
  'Children',
  'Adult',
  'KungFu',
  'Disaster',
  'Reality-TV',
  'Monster',
  'News',
  'Talk Show',
  'X-rated',
  'Costume',
  'Stage',
  'Opera',
  'Game-show',
  'Absurd',
  'Suspense'
];

export { buttonGroup, movieTypes, countriesName };
