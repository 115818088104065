import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { activateAccount } from '../utils/request';

const AccountActivationPage = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  const navigate = useNavigate();

  useEffect(async () => {
    try {
      await activateAccount(email, code);
      navigate('/activation-succeed');
    } catch (e) {
      console.log(e);
      // setErrorMessage(e);
    }
  }, []);

  return null;
};
export default AccountActivationPage;
