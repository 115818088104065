import React, { useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import styles from './FindYourAccountPage.module.css';
import { sendResetPwdEmail } from '../utils/request';
import LabelInput from '../components/LabelInput';

const FindYourAccountPage = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [usernameErrorToggle, setUsernameErrorToggle] = useState(false);
  const [isShowingLoading, setIsShowingLoading] = useState(false);
  const userNameRef = useRef();

  const inputErrorMessage = {
    username: 'Should be a valid email'
  };

  const validateUsername = (username) => {
    return username.match(/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/);
  };

  const submit = async () => {
    const username = userNameRef.current.value;

    let isUsernameVaild = validateUsername(username);
    setUsernameErrorToggle(!isUsernameVaild);

    if (isUsernameVaild) {
      try {
        setIsShowingLoading(true);
        await sendResetPwdEmail(username);
        setIsShowingLoading(false);
        setSuccessMessage(
          'Successed.We just send you an email.Please follow the steps in the email to reset your password.'
        );
      } catch (e) {
        setIsShowingLoading(false);
        setErrorMessage(e.response.data.message);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  useEffect(() => {
    userNameRef.current.focus();
  }, []);

  return (
    <div className={styles.container}>
      <h2>Find Your Account</h2>
      <div className={styles.error}>{errorMessage}</div>
      <form onSubmit={handleSubmit}>
        <LabelInput labelName={'Email'} inputRef={userNameRef} name={'username'} />
        <div className={styles.error}>{usernameErrorToggle && inputErrorMessage.username}</div>
        <button>{isShowingLoading && <ClipLoader color="#fff" size={20} />} Submit</button>
      </form>
      <div className={styles.succeed}>{successMessage}</div>
    </div>
  );
};

export default FindYourAccountPage;
