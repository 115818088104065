import axios from 'axios';
import { history } from './history';

const API_HOST = 'https://doumiao-api.herokuapp.com/api/v1';
// const API_HOST = 'http://localhost:5000/api/v1';

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('maimiao-token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      // Token is expired, redirect to /logout to reuse clean up process
      history.push('/logout');
    }
    return Promise.reject(error);
  }
);

export const getProfile = async () => {
  const res = await axios.get(`${API_HOST}/account/profile`);
  return res.data;
};

export const updateProfile = async (profile) => {
  const res = await axios.put(`${API_HOST}/account/profile`, {
    firstName: profile.firstName,
    lastName: profile.lastName,
    gender: profile.gender,
    age: profile.age
  });
  return res.data;
};

export const activateAccount = async (email, code) => {
  const res = await axios.post(`${API_HOST}/auth/activate-account`, {
    email,
    code
  });
  return res.data;
};

export const sendResetPwdEmail = async (email) => {
  const res = await axios.get(`${API_HOST}/auth/send-reset-pwd-email?email=${email}`);
  return res.data;
};

export const signin = async (username, password) => {
  const res = await axios.post(`${API_HOST}/auth/signin`, {
    username,
    password
  });
  return res.data;
};

export const signUp = async (email, password, firstName, lastName) => {
  const res = await axios.post(`${API_HOST}/auth/signup`, {
    email,
    password,
    firstName,
    lastName
  });
  return res.data;
};

export const resetPwd = async (username, password, code) => {
  const res = await axios.post(`${API_HOST}/auth/reset-pwd`, {
    username,
    password,
    code
  });
  return res.data;
};

export const rateMovie = async (movieId, score, comment) => {
  const res = await axios.post(`${API_HOST}/movie-rating`, {
    tmdbMovieId: movieId,
    score,
    comment
  });
  return res.data;
};

export const myReview = async (movieId, userId) => {
  const res = await axios.get(`${API_HOST}/movie-rating/search?tmdbMovieId=${movieId}&userId=${userId}`);
  return res.data;
};

export const reviewList = async (movieId) => {
  const res = await axios.get(`${API_HOST}/movie-rating/search?tmdbMovieId=${movieId}`);
  return res.data;
};

export const changePwd = async (oldPassword, newPassword) => {
  const res = await axios.post(`${API_HOST}/account/change-password`, {
    oldPassword,
    newPassword
  });
  return res.data;
};

export const getFriends = async () => {
  const res = await axios.get(`${API_HOST}/friends`);
  return res.data;
};

export const getFriendsUsername = async (inputValue) => {
  const res = await axios.get(`${API_HOST}/users/search?page=1&pageSize=100&searchTerm=${inputValue}`);
  return res.data;
};

export const inviteFriend = async (friendId, comment) => {
  const res = await axios.post(`${API_HOST}/friends/invitations`, {
    friendId,
    comment
  });
  return res.data;
};

export const getSentInvitations = async () => {
  const res = await axios.get(`${API_HOST}/friends/invitations/sent`);
  return res.data;
};

export const getReceivedInvitations = async () => {
  const res = await axios.get(`${API_HOST}/friends/invitations/received`);
  return res.data;
};

export default axios;
