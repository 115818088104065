import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from './Button';
import {
  fetchOnPopularMovies,
  fetchOnTopRateMovies,
  fetchOnShowingMovies
  // fetchOnLatestMovies
} from '../../slices/movieSlice';
import styles from './ButtonGroup.module.css';
import { buttonGroup } from '../../data/home_page';

const ButtonGroup = () => {
  const [activeBtn, setActiveButton] = useState('onshowing');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOnShowingMovies());
  }, []);

  const handleClick = (id) => {
    setActiveButton(id);
    switch (id) {
      case 'onshowing':
        dispatch(fetchOnShowingMovies());
        break;
      case 'popular':
        dispatch(fetchOnPopularMovies());
        break;
      case 'toprate':
        dispatch(fetchOnTopRateMovies());
        break;
      // case 'latest':
      //   dispatch(fetchOnLatestMovies());
      //   break;
    }
  };

  return (
    <div className={styles['btn-group']}>
      {buttonGroup.map((btn) => (
        <Button key={btn.id} primary={activeBtn === btn.id} fontWeight="bold" onClick={() => handleClick(btn.id)}>
          {btn.label}
        </Button>
      ))}
    </div>
  );
};
export default ButtonGroup;
