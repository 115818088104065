import React, { useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Link, useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../utils/anthentication';
import { signUp } from '../utils/request';
import styles from './SignupPage.module.css';
import LabelInput from '../components/LabelInput';

const SignupPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [firstNameErrorToggle, setFirstNameErrorToggle] = useState(false);
  const [lastNameErrorToggle, setLastNameErrorToggle] = useState(false);
  const [emailErrorToggle, setEmailErrorToggle] = useState(false);
  const [passwordErrorToggle, setPasswordErrorToggle] = useState(false);
  const [confirmPasswordErrorToggle, setConfirmPasswordErrorToggle] = useState(false);
  const [isShowingLoading, setIsShowingLoading] = useState(false);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const inputErrorMessage = {
    firstName: 'First name should contains 1-25 characters',
    lastName: 'Last name should contains 1-25 characters',
    email: 'Should be a valid email',
    password:
      'Password should contains at least one upper case letter, one lower case letter one number and one special character and within 8 to 16 characters',
    confirmPassword: 'Confirm password should be same as password'
  };
  let navigate = useNavigate();

  const validateFirstName = (firstName) => {
    return firstName.length >= 1 && firstName.length <= 25;
  };
  const validateLastName = (lastName) => {
    return lastName.length >= 1 && lastName.length <= 25;
  };
  const validateEmail = (email) => {
    return email.match(/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/);
  };
  const validatePassword = (password) => {
    return password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~!@#$%^&*]{8,16}$/);
  };
  const validateConfirmPassword = (confirmPassword, password) => {
    return confirmPassword === password;
  };

  const handleSignup = async () => {
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    const email = usernameRef.current.value;
    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    let isFirstNameValid = validateFirstName(firstName);
    setFirstNameErrorToggle(!isFirstNameValid);

    let isLastNameValid = validateLastName(lastName);
    setLastNameErrorToggle(!isLastNameValid);

    let isEmailValid = validateEmail(email);
    setEmailErrorToggle(!isEmailValid);

    let isPasswordValid = validatePassword(password);
    setPasswordErrorToggle(!isPasswordValid);

    let isConfirmPasswordValid = validateConfirmPassword(confirmPassword, password);
    setConfirmPasswordErrorToggle(!isConfirmPasswordValid);

    if (isFirstNameValid && isLastNameValid && isEmailValid && isPasswordValid && isConfirmPasswordValid) {
      try {
        setIsShowingLoading(true);
        await signUp(email, password, firstName, lastName);
        setIsShowingLoading(false);
        navigate('/signup-complete');
      } catch (e) {
        setIsShowingLoading(false);
        setErrorMessage(e.response.data.message);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignup();
  };

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/');
    }
  }, []);

  return (
    <div className={styles.container}>
      <h1>User Registration</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <div className={styles.error}>{errorMessage}</div>
          <LabelInput labelName={'First name'} inputRef={firstNameRef} name={'firstname'} />

          <div className={styles.error}>{firstNameErrorToggle && inputErrorMessage.firstName}</div>
          <LabelInput labelName={'Last name'} inputRef={lastNameRef} name={'lastname'} />

          <div className={styles.error}>{lastNameErrorToggle && inputErrorMessage.lastName}</div>
          <LabelInput labelName={'Email'} inputRef={usernameRef} name={'username'} />
          <div className={styles.error}>{emailErrorToggle && inputErrorMessage.email}</div>

          <LabelInput type={'password'} labelName={'Password'} inputRef={passwordRef} name={'password'} />
          <div className={styles.error}>{passwordErrorToggle && inputErrorMessage.password}</div>
          <LabelInput
            type={'password'}
            labelName={'Confirm password'}
            inputRef={confirmPasswordRef}
            name={'confirmPassword'}
          />
          <div className={styles.error}>{confirmPasswordErrorToggle && inputErrorMessage.confirmPassword}</div>
        </div>
        <button>{isShowingLoading && <ClipLoader color="#fff" size={20} />} Submit</button>
      </form>
      <div className={styles.link}>
        Already have an account? <Link to="/login">Sign in</Link>
      </div>
    </div>
  );
};
export default SignupPage;
