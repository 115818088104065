import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ProfileMenu.module.css';

const ProfileMenu = () => {

  return (
    <div className={styles.tab}>
      <button className={styles.title}>Profile</button>
      <div className={styles['dropDown-content']}>
        <svg _ngcontent-globalNav-c42="" className={styles.svg}>
          <path _ngcontent-globalNav-c42="" d="M8 0 V16 L4 12 Q0 8 4 4z"></path>
        </svg>

        <div className={styles.link}>
          <Link to="/profile">My profile</Link>
          <Link to="/change-password">Change password</Link>
          <Link to="/friends">Friends</Link>
        </div>
      </div>
    </div>
  );
};
export default ProfileMenu;
