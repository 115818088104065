import { useSearchParams } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ThemeContext } from '../context/ThemeContext';
import styles from './SearchDetailPage.module.css';
import SearchCard from '../components/SearchCard';
import { Link } from 'react-router-dom';

const SearchDetailPage = () => {
  const [searchParams] = useSearchParams();
  const movieKeyword = searchParams.get('keyword');
  const [movies, setMovies] = useState([]);
  const { theme } = useContext(ThemeContext);
  const themeClass = theme === 'light' ? styles.light : styles.dark;

  useEffect(async () => {
    const url = `https://api.themoviedb.org/3/search/movie?api_key=71b734c9fe036fa5b36e3d80555e9e37&language=en-US&page=1&include_adult=false&query=${movieKeyword}`;
    const res = await axios.get(url);
    const searchResults = res.data.results;
    setMovies(searchResults);
  }, []);

  return (
    <div className={themeClass}>
      <div className={styles.content}>
        <Link to="/">&lt; Back</Link>

        <h2>
          Search &quot;{movieKeyword} &quot; ，found {movies.length} results:
        </h2>
        <div className={styles.card}>
          {movies
            ? movies.map((movie) => (
                <SearchCard
                  key={movie.id}
                  title={movie.title}
                  image={movie.poster_path}
                  id={movie.id}
                  date={movie.release_date}
                  rating={movie.vote_average}
                />
              ))
            : 'No matched movies found!'}
        </div>
      </div>
    </div>
  );
};
export default SearchDetailPage;
