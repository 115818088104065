import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AccountActivationSucceedPage.module.css';

const AccountActivationSucceedPage = () => {
  return (
    <div className={styles.container}>
      <h1>Welcome to Tastesay</h1>
      <p>Congratulations, your account is activated successfully!</p>
      <Link to="/login">
        <button>Login now</button>
      </Link>
    </div>
  );
};
export default AccountActivationSucceedPage;
