import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Rating } from 'react-simple-star-rating';
import styles from './ReviewCard.module.css';

const ReviewCard = (props) => {
  const { person, date, score, comment } = props;
  const [readMore, setReadMore] = useState(false);
  const [needHidden, setNeedHidden] = useState(false); 

  useEffect(() => {
    if (comment.length> 200) {
      return setNeedHidden(true);
    }
  }, [comment.length]);

  return (
    <div className={styles.card}>
      <span className={styles.name}>{person.firstName + ' ' + person.lastName}</span>
      <span className={styles.star}>
        <Rating ratingValue={score * 10} allowHalfIcon size={20} allowHover={false} readonly />
      </span>
      <span className={styles.date}>{dayjs(date).format('YYYY-MM-DD HH:mm:ss')}</span>
      {/* <div className={readMore ? styles.word_expand : styles.word}>
        what ever.................. what ever.................. what ever.................. what ever..................
        what ever.................. what ever.................. what ever.................. what ever..................
        what ever.................. what ever.................. what ever.................. what ever..................
        what ever.................. what ever.................. what ever.................. what ever..................
        what ever.................. what ever.................. what ever..................
        <span className={styles.plus} onClick={() => setReadMore(!readMore)}>
          +
        </span>
      </div> */}
      <div>
        {needHidden && !readMore ? `${comment.substring(0, 200)}...` : comment}
        {needHidden && (
          <button className={styles.show} onClick={() => setReadMore(!readMore)}>
            {readMore ? 'Stow' : 'Expand'}
          </button>
        )}
      </div>
    </div>
  );
};

ReviewCard.propTypes = {
  comment: PropTypes.string,
  date: PropTypes.string,
  score: PropTypes.number,
  person: PropTypes.string
};

export default ReviewCard;
