import React, { useState } from 'react';
import Avatar from './Avatar';
import PropTypes from 'prop-types';
import styles from './FriendCard.module.css';

const FriendCard = (props) => {
  const { title, subTitle, actions, status, onClick } = props;
  const [isActionVisible, setIsActionVisible] = useState(false);

  return (
    <div className={styles.container}>
      <Avatar>{title.charAt(0)}</Avatar>
      <div className={styles.title}>
        <div>{title}</div>
        <div>{subTitle}</div>
      </div>

      {status && <div className={styles.status}>{status}</div>}

      {actions && (
        <div className={styles.actions}>
          <div onClick={() => setIsActionVisible(!isActionVisible)} className={styles.dot}>
            &#8230;
          </div>
          <div className={isActionVisible ? styles.onShowing : styles.hidden}>
            {actions.map((action) => (
              <div key={action} onClick={onClick} className={styles.action}>
                {action}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

FriendCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  actions: PropTypes.array,
  status: PropTypes.string,
  onClick: PropTypes.func
};

export default FriendCard;
