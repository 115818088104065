import React, { useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Link, useSearchParams } from 'react-router-dom';
import styles from './ResetPasswordPage.module.css';
import { resetPwd } from '../utils/request';
import LabelInput from '../components/LabelInput';

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');
  const code = searchParams.get('code');
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordErrorToggle, setPasswordErrorToggle] = useState(false);
  const [confirmPasswordErrorToggle, setConfirmPasswordErrorToggle] = useState(false);
  const [resetSucceed, setResetSucceed] = useState(false);
  const [isShowingLoading, setIsShowingLoading] = useState(false);

  const inputErrorMessage = {
    password:
      'Password should contains at least one upper case letter, one lower case letter one number and one special character and within 8 to 16 characters',
    confirmPassword: 'Confirm password should be the same as above'
  };
  const resetSucceedMessage = 'The password reset correctly. You can re-login now.';

  const validatePassword = (password) => {
    return password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~!@#$%^&*]{8,16}$/);
  };
  const validateConfirmPassword = (confirmPassword, password) => {
    return confirmPassword === password;
  };

  const handleLogin = async () => {
    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    let isPasswordVaild = validatePassword(password);
    setPasswordErrorToggle(!isPasswordVaild);

    let isConfirmPasswordVaild = validateConfirmPassword(confirmPassword, password);
    setConfirmPasswordErrorToggle(!isConfirmPasswordVaild);

    if (isPasswordVaild && isConfirmPasswordVaild) {
      try {
        setIsShowingLoading(true);
        await resetPwd(username, password, code);
        setIsShowingLoading(false);
        setResetSucceed(true);
      } catch (e) {
        setIsShowingLoading(false);
        setErrorMessage(e.response.data.message);
      }
    }
  };
  useEffect(() => {
    passwordRef.current.focus();
  }, []);

  return (
    <div className={styles.container}>
      <h2>Reset Password</h2>

      <div className={styles.error}>{errorMessage}</div>
      <LabelInput type={'password'} labelName={'Password'} inputRef={passwordRef} name={'password'} />
      <div className={styles.error}>{passwordErrorToggle && inputErrorMessage.password}</div>
      <LabelInput
        type={'password'}
        labelName={'Confirm password'}
        inputRef={confirmPasswordRef}
        name={'confirmPassword'}
      />
      <div className={styles.error}>{confirmPasswordErrorToggle && inputErrorMessage.confirmPassword}</div>
      <button onClick={handleLogin}>{isShowingLoading && <ClipLoader color="#fff" size={20} />} Submit</button>
      <div className={styles.succeed}>{resetSucceed && resetSucceedMessage}</div>
      <Link to="/login">{resetSucceed && <button>Login</button>}</Link>
    </div>
  );
};
export default ResetPasswordPage;
