import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <section className={styles.section}>
      <div className={`container ${styles.footer}`}>
        <span>© 2022 Tastesay Ltd</span>
        <div>
          <Link to="/terms-conditions">Terms&Conditions</Link>
          <Link to="/">Privacy Policy</Link>
        </div>
      </div>
    </section>
  );
};

export default Footer;
