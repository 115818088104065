import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './SearchCard.module.css';

const SearchCard = (props) => {
  const { title, image, rating, date, id } = props;
  const imageBaseUrl = 'https://image.tmdb.org/t/p/w500/';

  return (
    <div className={styles.detail}>
      <div className={styles.picture}>
        <Link to={`/movie-detail?id=${id}`}>
          <img src={`${imageBaseUrl}${image}`} />
        </Link>
        <div className={styles.header}>
          <span className={styles.name}>{title}</span>
          <span className={styles.rating}>{rating}/10</span>
        </div>
        <p>Release date: {date}</p>
        <Link to={`/movie-detail?id=${id}`}>
          <h2>More Info</h2>
        </Link>
      </div>
    </div>
  );
};

SearchCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  rating: PropTypes.string,
  date: PropTypes.string,
  id: PropTypes.string
};

export default SearchCard;
