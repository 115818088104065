import React, { useContext, useState } from 'react';
import Switch from 'react-switch';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeContext } from '../context/ThemeContext';
import ProfileMenu from './ProfileMenu';
import styles from './Header.module.css';
import MenuIcon from './MenuIcon';

const Header = () => {
  const { theme, updateTheme } = useContext(ThemeContext);
  const [isToggled, setIsToggled] = useState(false);

  const handleChange = (checked) => {
    const currentTheme = checked ? 'light' : 'dark';
    updateTheme(currentTheme);
  };
  const isLoggedIn = useSelector((state) => state.account.isLoggedIn);

  const handleRedirect = () => {
    setIsToggled(false);
  };

  return (
    <>
      <div className={isToggled ? `container ${styles.header} ${styles.header_toggled}` : `container ${styles.header}`}>
        <div className={styles.logo}>
          <Link to="/">Tastesay</Link>
        </div>
        <div className={styles.menu_wrapper}>
          <nav className={styles.menu}>
            <Link className={styles.hide_mobile} to="/">
              Movie
            </Link>
            <Link className={styles.hide_mobile} to="/about">
              About Tastesay
            </Link>
            {isLoggedIn && <ProfileMenu />}
            {isLoggedIn ? (
              <Link to="/logout" onClick={handleRedirect}>
                Logout
              </Link>
            ) : (
              <Link to="/login" onClick={handleRedirect}>
                Login
              </Link>
            )}

            <Switch
              width={70}
              onChange={handleChange}
              checked={theme === 'light'}
              uncheckedIcon={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: 13,
                    paddingRight: 2,
                    color: 'white'
                  }}
                >
                  Dark
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: 13,
                    paddingRight: 2,
                    color: 'white'
                  }}
                >
                  Light
                </div>
              }
            />
          </nav>
          <MenuIcon isToggled={isToggled} onToggled={setIsToggled} />
        </div>
      </div>
      <div className={styles.mobile_only_menu} style={{ display: isToggled ? 'block' : 'none' }}>
        <nav>
          <Link to="/" onClick={handleRedirect}>
            HomePage
          </Link>
          <Link to="/about" onClick={handleRedirect}>
            About Tastesay
          </Link>
          {isLoggedIn && (
            <>
              <span className={styles.menuitem_l1}>Profile</span>
              <ul>
                <li>
                  <Link className={styles.menuitem_l2} to="/profile" onClick={handleRedirect}>
                    My profile
                  </Link>
                </li>
                <li>
                  <Link className={styles.menuitem_l2} to="/change-password" onClick={handleRedirect}>
                    Change password
                  </Link>
                </li>
                <li>
                  <Link className={styles.menuitem_l2} to="/friends" onClick={handleRedirect}>
                    Friends
                  </Link>
                </li>
              </ul>
            </>
          )}
        </nav>
      </div>
    </>
  );
};

export default Header;
